<template>
  <div class="pl-8 pr-8">
    <div class="mb-xl-margin-file-sign">
      <v-row class="mt-10">
        <v-col class="text-left navidad-text" cols="6">
          <h1 class="reference__title">Crear proceso disciplinario</h1>
        </v-col>
      </v-row>
      <v-form ref="form">
        <v-card outlined class="reference__card px-8 mt-8" elevation="0">
          <!-- Filtros -->
          <v-row class="mt-2">
            <!-- Fecha -->

            <v-col cols="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="150"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.selectedDate"
                    label="Seleccionar fecha*"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.selectedDate"
                  :max="maxDate"
                  :min="minDate"
                >
                  <v-btn
                    text
                    color="primary"
                    @click="(menu = false), (form.selectedDate = null)"
                  >
                    Cancelar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>


            <!-- Campo de selección de hora -->
            <v-col cols="6">
              <v-menu
                ref="menuHora"
                v-model="menuHora"
                :close-on-content-click="false"
                :nudge-right="150"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                    v-model="form.selectedTime"
                    label="Seleccionar hora*"
                    prepend-inner-icon="mdi-clock-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-time-picker v-model="form.selectedTime" format="24hr">
                  <v-btn
                    text
                    color="primary"
                    @click="(menuHora = false), (form.selectedTime = null)"
                  >
                    Cancelar
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-col>
            <!-- Usuario -->
            <v-col cols="6">
              <v-autocomplete
              :rules="[(v) => !!v || 'Este campo es requerido']"
                prepend-inner-icon="mdi-account-outline"
                color="#466be3"
                :items="listUserAll"
                v-model="form.listUserAllId"
                item-text="user"
                item-value="document_number"
                label="Nombre - Número de cédula - Usuario de red"
                required
              >
                <template v-slot:selection="data">
                  <v-chip small dark v-bind="data.attrs" color="#466be3">
                    {{ data.item.user }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- Motivo -->
            <v-col cols="12">
              <v-textarea
                :rules="[(v) => !!v || 'Este campo es requerido']"
                v-model="form.motivo"
                name="input-7-1"
                label="Motivo*"
                counter=""
                value=""
                rows="4"
                required
              ></v-textarea>
            </v-col>
            <!-- Boton -->
            <v-col cols="6">
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="createProcess()"
                  block
                  rounded
                  style="color: #fff"
                  :style="{
                    'background-color': hover ? '#324CA1' : '#466BE3',
                  }"
                  class="mt-2 ml-2 mb-5"
                >
                  Crear proceso
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </div>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  mixins: [color],
  data() {
    return {
      director:false,
      menuHora: false,
      motivo: "",
      form: {
        motivo: "",
        selectedDate: new Date().toISOString().substr(0, 10),
        selectedTime: null,
        listUserAllId: "",
      },
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loadingAll: false,
      listUserAll: [],
      listUserAllId: [],
      menu: false,
      minDate: new Date(new Date().setDate(new Date().getDate() - 100))
        .toISOString()
        .substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      currentUser: [],
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    listUsers() {
      let AllUser = this.$store.getters.getAllUsersNickName;
      this.listUserAll = AllUser;

      let currentUser = this.$store.getters.getUser;
      this.currentUser = currentUser;

      this.director = this.containsDirectorOrCoordinador(this.$store.getters.getUser.profile.name)
    },
    createProcess() {
      console.log(this.$refs.form.validate())
      if (this.$refs.form.validate()) {

        var data = {
          fecha: this.form.selectedDate,
          hora: this.form.selectedTime,
          motivo: this.form.motivo,
          document_number: this.form.listUserAllId,
          user_id: this.currentUser.id,
          director : this.director,
        };
        this.loadingAll = true;
        Api.disciplinaryProcesses()
          .createProcess(data, this.token)
          .then((res) => {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            this.message.title = "Excelente";
            this.message.type = "success";
            this.cleanData();
          })
          .catch((error) => {
            this.message.dialog = true;
            this.message.sms = "Hemos tenido problemas con tu solicitud";
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          })
          .finally(() => {
            this.loadingAll = false;
          });
      }
    },
    cleanData() {
      this.motivo = "";
      this.listUserAllId = "";
      this.selectedTime = null;
    },
    containsDirectorOrCoordinador(text) {
      const regex = /director|coordinador/i; // The 'i' indicates that the search should be case-insensitive
      return regex.test(text);
    },
  },
  created() {
    this.getToken();
    setTimeout(() => {
      this.listUsers();
    }, 5000);
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.navidad-text {
  display: flex;
}

.reference__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}

tr,
th,
span .reference__table .text-start .sortable {
  font-size: 16px !important;
}

.reference__card {
  border-radius: 15px !important;
}

.reference__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.references__header {
  color: #353535 !important;
}
</style>
